.Ministries{
    max-width: 61rem;
    min-height: 38rem;
    margin: 2.5rem;
    padding:2.5rem;
    margin:0 auto;
}

.Ministry{
    position: relative;
    background: url(./Media/pastor.jpeg)no-repeat;
    background-size: cover;
    background-position: center;
    height: 90vh;
}

h6{
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
}
.Ministries h2{
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 500;
    color: #926F34;
    border-bottom: 1px solid #926F34;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
}
.Ministriessection{
    padding:2.5rem;
    display: flex;
    flex-direction: column; 
}
.msection img{
    max-width: 29.8125rem;
    min-height: 25rem;
    padding-top: 1rem;
}
.ministriesInfo{
    float: right;
    padding-top: 1rem;
    max-width: 40%;
    text-align: justify;
    margin-top: 2rem;
    min-height: 9.875rem;
}
.Ministriesleft{
    max-width: 61rem;
    min-height: 28rem;
    margin: 2.5rem;
    padding:2.5rem;
    margin:0 auto;
}
.Ministriesleft h2{
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 500;
    color: #926F34;
    border-bottom: 1px solid #926F34;
    text-align: right;
    text-transform: capitalize;
    width: 100%;
}
.Ministriessectionleft{
    display: flex;
    flex-direction: column; 
}
.msectionleft img{
    max-width: 29.8125rem;
    min-height: 17.390625rem;
    padding-top: 1rem;
}
.ministriesInfoleft{
    float: left;
    margin-top: 5rem;
    max-width: 40%;
    text-align: justify;
    min-height: 9.875rem;
    margin-right: 1rem;
}



.contacts{
  max-width:60.9375rem;
  display: flex;
  position: relative;
  padding-top: 2rem;
  margin:0 auto;
  flex-direction: column;
  justify-content: space-around;
}
.contacts h4{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5625rem;
    font-weight: 500;
    color: #926F34;
    text-align: left;
}
.contacts h6{
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    color: #000;
}
.contacts h5{
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    color: #926F34;
}
.footer iframe{
    width:100%;
    min-height: 28.298125rem;
    border-top: 1px solid #926F34;
}
@media(max-width:800px){
    .msection img{
        width: 15.8125rem;
        height: 25rem;
        padding-top: 1rem;
    }
    .msectionleft img{
        width: 15.8125rem;
        height: 10.390625rem;
        padding-top: 1rem;
    }
    .ministriesInfo,.ministriesInfoleft{
        max-width: 100%;
    }
    .footer iframe{
        width:100%;
        min-height: 28.298125rem;
        border-top: 1px solid #926F34;
    }
} 
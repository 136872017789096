.Welcome2 img{
    padding: 2.5rem;
}
.About{
    position: relative;
    background: url(./Media/worship.jpeg)no-repeat;
    background-size: cover;
    background-position: center;
    height: 90vh;
}

.Welcome2{
    max-width:60.9375rem;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    text-align: justify;
    align-items: center;
}
.Welcome2 h2{
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 500;
    color: #926F34;
    border-bottom: 1px solid #926F34;
    text-align: left;
    text-transform: capitalize;
}
h4{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    color: #926F34;
    border-bottom: 1px solid #926F34;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}
.Welcome2 h5{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    padding: 2.5rem;
    font-size: 0.875rem;
    line-height: 1.5625rem;
    color: #000;
}

@media(max-width:800px){
    .Welcome2 img{
        max-width: 100%;
    }
    .Welcome2 h2{
        margin-left: 2rem;
    }
}
.content{
    max-width: 61rem;
    margin:0 auto;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
   
}
.video video{
    width: 100%;
}
.caption{
    padding:2.5rem;
    max-width: 40rem;
    margin: 0 auto;
    text-align: center;
}
.caption h6{
    font-size: 1rem;
    line-height: 1.5625rem;
    color: #926f34;
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.grid{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 5vw);
    grid-gap: 15px;
}
.grid img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-0 { 
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
 }
 .item-1 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
 }
 .item-2 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 6;
 }
 .item-3 { 
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 6;
 }
 .item-4 { 
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 9;
 }
 .item-5 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 6;
    grid-row-end: 9;
 }
 .item-6 { 
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 9;
    grid-row-end: 11;
 }
 .item-7 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 9;
    grid-row-end: 11;
 }
 .item-8 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 11;
    grid-row-end: 14;
 } 
 .item-9{
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 11;
    grid-row-end: 14; 
 }
 .item-10{
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 14;
    grid-row-end: 17;
 }
 .item-11{
    grid-column-start: 4;
    grid-column-end: 9;
    grid-row-start: 14;
    grid-row-end: 17;
 }
 @media(max-width: 1000px){
   .grid{
      display: block;
   }
 }
 
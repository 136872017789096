.eventcard{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 0.5rem;
    align-items: flex-start;
    padding-top: 1.25rem;
    gap: 1.25rem;
    max-width: 60.9375rem;
    border: 1px solid rgba(146, 111, 52, 0.2);
    border-radius: 1.25rem;
}

.eventcard h2{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    color: #000;
    border: none;
    text-align: center;
}
.eventcard h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    color: #926F34;
    text-align: center;
}
.eventcard h6{
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
}
.Events{
    position: relative;
    background: url(./Media/praise.jpeg)no-repeat;
    background-size: cover;
    background-position: center;
    height: 90vh;
}
.details{
    margin: 2rem;
    max-width: 60.9375rem;
    line-height: 2.5rem; 
}
.details h6,.details ol{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
}
.service{
    max-width: 61rem;
    margin: 2.5rem;
    padding: 2.5rem;
    margin:0 auto;
}
.service h2{
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 500;
    color: #926F34;
    border-bottom: 1px solid #926F34;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
}
.service h3{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5625rem;
    font-weight: 500;
    text-align: justify;
    color: #926F34;
}
.events{
    max-width: 60.9375rem;
    display: flex;
    margin: 0 auto;
    padding-top: 7.5rem;
    padding-bottom: 3.75rem;
    flex-direction: column;
    justify-content: space-between;
    text-align: justify;
}
.events h2{
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 500;
    color: #926F34;
    border-bottom: 1px solid #926F34;
    text-align: left;
    text-transform: capitalize;
}
.cards{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.eventscard{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:2.5rem 1.25rem;
    gap:1.25rem;
    max-width: 28.625rem;
    border: 1px solid #926F34;
    border-radius:1.25rem;
}
.eventscard img{
    width: 21.2625rem;
    height: 11.375rem;
    margin:0 auto;
}
.eventscard2 img{
    width: 21.5625rem;
    height: 11.375rem;
    margin:0 auto;
}
.eventsinfo h2{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    color: #000;
    border: none;
    text-align: center;
}
.eventsinfo h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size:1.25rem;
    color: #926F34;
    text-align: center;
}
.eventsinfo h6{
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: capitalize;
}
.eventscard2{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:2.5rem 2.25rem;
    gap:1.25rem;
    max-width: 28.625rem;
    margin: 0 auto;
    border: 1px solid rgba(146, 111, 52, 0.2);
    border-radius:1.25rem;
}
@media(max-width:800px){
    .cards{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 62rem;    
    }
    .details ol{
        width: 20rem;
    }
    .details{
        margin-left: -2rem;
    }
    iframe{
        width: 20rem;
        height: 20rem;
    }
    .eventscard h2,.eventscard2 h2{
        margin-left: -2rem;
    }
    .eventscard2 img{
        max-width: 20rem;
        margin-left: -2rem;
    }
    .eventscard2{
        max-width: 21rem;
    }
    .service h2{
        margin-left: -2rem;
    }
}

